/* Angular CDK */
@import '@angular/cdk/overlay-prebuilt.css';
@import './assets/skote-theme/scss/custom/fonts/fonts';

@import './theme/bootstrap-utilities.css';

.material-icons {
    font-size: inherit;
}

.cdk-overlay-container {
    z-index: 20005; // 20004 is z-index of ion-modal
}

body {
    color: rgba(0, 0, 0, 0.87);
    background-color: #fafafa;
}

.badge-todo,
.badge-free {
    display: inline-block;
    width: 1.25em;
    height: 0;
    position: relative;
    margin-right: 0.2em;
}

.badge-todo::before,
.badge-free::before {
    display: inline-block;
    color: white;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-40%, -70%) rotate(-90deg);
    padding: 0.32em 0.35em 0.1em;
    line-height: 1.2;
    text-shadow: 0 0 0.3em rgba(black, 0.5);
}

.badge-todo::before {
    content: 'TODO';
    background: #fa0;
}

.badge-free::before {
    content: 'FREE';
    background: #0b0;
}

// TEMP fix
.cdk-overlay-container {
    z-index: 30000; // to overlay <ion-modal> with dynamically generated z-index: 20000+
}

.mat-snack-bar.error {
    background-color: #dc3545 !important;
    font-weight: bolder;
    color: white;
}

.mat-toolbar {
    flex-basis: 64px;
    flex-grow: 0;
}

.mat-button-toggle {
    font-size: 14px;
}

.action-icon {
    cursor: pointer;
    opacity: 0.6;
    &:hover {
        opacity: 1;
    }
}

.form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1em;
    max-width: 600px;

    > * {
        flex-basis: 100px;
        flex-grow: 1;
    }
}

.alert-info {
    color: #055160;
    background-color: #cff4fc;
    border-color: #b6effb;
}

.alert-danger {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}

.alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.animate-2s {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
}

.bounce-mat-icon {
    animation-name: bounce-mat-icon;
    animation-timing-function: ease;
}
@keyframes bounce-mat-icon {
    0% {
        transform: scale(1, 1) translateY(0);
    }
    10% {
        transform: scale(1.1, 0.9) translateY(0);
    }
    30% {
        transform: scale(0.9, 1.1) translateY(-24px);
    }
    50% {
        transform: scale(1, 1) translateY(0);
    }
    57% {
        transform: scale(1, 1) translateY(-7px);
    }
    64% {
        transform: scale(1, 1) translateY(0);
    }
    100% {
        transform: scale(1, 1) translateY(0);
    }
}

a.semi-text {
    color: #053379;
    text-decoration-color: #05337933;

    &:hover {
        color: #0d6efd;
        text-decoration-color: currentcolor;
    }
}

.msg-col {
    flex-basis: calc(50% - 2em);
    margin-left: 1em;
    margin-right: 1em;

    > div {
        flex-grow: 1;
        max-height: 24em;
    }
}

// backdrop hack (I was unable to turn of backdrop for css-switcher component)
.cdk-overlay-backdrop:has(+ .cdk-overlay-connected-position-bounding-box .has-no-backdrop) {
    pointer-events: none;
}
.cdk-overlay-pane:has(> div .css-switcher-popup) {
    transform: translateX(-35%);
}
